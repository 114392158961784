import React, { Component } from 'react';
import ControlInfoPiece from '../ControlInfoPiece';
import pauseImage from '../../assets/images/pause.png';
import speedImage from '../../assets/images/slow-txt.png';
import networkButtonImg from '../../assets/images/network-button.png';
import playheadImg from '../../assets/images/playhead.png';
import infoImg from '../../assets/images/info_icon.png';

const ControlsPopup = ({panelId, learnMoreClick}) => {
    return (
      <div className='controlPopup'>
        <div className="controlPopupTitle">
            CONTROLS
        </div>
        <div className='controlInfoContainer'>
            <div className='flexContainer'>
                <div>
                    <ControlInfoPiece image={playheadImg} title='TIMELINE' info='Use this slider to adjust the time'/>
                    <ControlInfoPiece image={pauseImage} title='PAUSE' info='Pause the advancing timeline'/>
                    <ControlInfoPiece image={speedImage} title='SPEED' info='Adjust the speed of the timeline'/>
                </div>
                <div className='controlInfoPieceRight'>
                    <ControlInfoPiece image={infoImg} title='INFORMATION' info='Learn more about what you are seeing'/>
                    <ControlInfoPiece image={networkButtonImg} title='NEURAL NETWORK' info='Learn more about the neural network'/>
                </div>
            </div>
        </div>

      </div>
    );
}

export default ControlsPopup;
