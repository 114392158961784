import React, { Component } from 'react';
import PropTypes from 'prop-types';
import networkButtonImg from '../../assets/images/network-button.png';

const FooterPopup = ({panelId, learnMoreClick}) => {
    return (
      <div className='footerPopup'>
        <div className={panelId === 0 ? 'footerPopupButton-active': 'footerPopupButton'} onClick={() => learnMoreClick(0)}>WHAT IS AN ACCELERATOR?</div>
        <div className={panelId === 1 ? 'footerPopupButton-active': 'footerPopupButton'} onClick={() => learnMoreClick(1)}>WHY ACCELERATORS MATTER</div>
        <div className={panelId === 2 ? 'footerPopupButton-active': 'footerPopupButton'} onClick={() => learnMoreClick(2)}>UNCONVENTIONAL ACCELERATORS</div>
        <div className={panelId === 3 ? 'footerPopupButton-active': 'footerPopupButton'} onClick={() => learnMoreClick(3)}>NEUROMORPHIC ACCELERATORS</div>
        <div className={panelId === 4 ? 'footerPopupButton-active': 'footerPopupButton'} onClick={() => learnMoreClick(4)}>NOVEL ACCELERATORS</div>
        <div className={panelId === 5 ? 'footerPopupButton-active': 'footerPopupButton'} onClick={() => learnMoreClick(5)}>RECURRENT NEURAL NETWORK</div>
      </div>
    );
}

export default FooterPopup;
