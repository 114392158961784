import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AccPopupImage from '../../assets/images/why_image.png';

class WhyAccPopup extends Component {
  constructor(props){
    super(props);
    this.state = { i: 0 };
  }

  render(){
      const {
        onCloseClick
      } = this.props;
    return(
      <div className='whyPopup flexContainer'>
        <div className='whyPopupLeft '>
            <div className='whyPopupTitle'>
                WHY ACCELERATORS MATTER
            </div>
            <div className='flexContainer popupContentAdjust whyDescContainer'>
                <div className='whyPopupDesc'>
                    <p>
                        Today, we are experiencing an exponential increase in data, coming from an explosion of sources. With transistor scaling slowing, the gap between what we want to accomplish and what we can accomplish is widening.                    </p>
                    <p>
                        We also have a sustainability problem. Today, IT infrastructure consumes over 10% of global energy production. And that production can only grow slowly.  
                    </p> 
                </div>
                <div className='whyPopupDesc2'>
                    <p>
                        So we have an energy gap too. Unless we do something radical.
                    </p>
                    <p>
                        Another important trend is the movement of compute to the edge within the devices where the data is created. 
                    </p>
                    <p>
                        Edge devices need to be smaller and more energy efficient than those that live within the data centers. Again, accelerators can help.
                    </p>
                </div>
            </div>
            <div className='whyQuoteBox'>
                <div>
                    Accelerators can help.
                </div>
                <div>
                    Because they perform specific tasks much faster and using much less <br/> energy, the whole system becomes more efficient.
                </div>
            </div>
        </div>
        <div className='whyPopupRight'>
            <div className='whyImageContainer'>
                <div className='whyMoores boldFamily'>
                    Moore's Law vs. the Law of Diminishing Returns
                </div>
                <div className='whyMooresText'>
                    From the launch of the first computer chip, we came to expect the number of transistors to increase and their size to decrease.
                </div>
                <img src={AccPopupImage}/>
            </div>
            
        </div>
        
      </div>
    );
  }

}

export default WhyAccPopup;
