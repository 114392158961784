import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Close } from 'grommet-icons';
import AccPopupImage from '../../assets/images/unconventional_image.jpg';

class UnconventionalPopup extends Component {
  constructor(props){
    super(props);
    this.state = { i: 0 };
  }

  render(){
      const {
          closeClicked
      } = this.props;
    return(
      <div className='unconPopup'>
        <div className='unconFade' />
          <div className='unconPopupLeft'>
            <div className='unconPopupTitle'>
                UNCONVENTIONAL ACCELERATORS
            </div>
            <div className='unconPopupDesc popupContentAdjust'>
                <div className='unconContent'>
                    <p>
                    Today’s conventional accelerators are all based on conventional digital logic that is subject to the scaling limitations of Moore’s law. There are physical limits to the ability to continually shrink the size of components on a chip.
                    </p>
                    <p>
                    Analog acceleration is a very active field of research in universities and startups across the world.
                    </p>
                </div>
                <div className='unconContent2'>
                    <p>
                    We believe it’s our memristor technology that differentiates our accelerators. Memristors promise greater performance, power efficiency, and scalability than other approaches. 
                    </p>
                </div>
            </div>
            <div className='unconQuoteBox'>
              <div>
                Labs’ accelerators are fundamentally different. Instead of transistor-based digital logic, we are harnessing the power of the analog world to achieve orders of magnitude faster computation and lower energy consumption. And we’ve also developed the perfect architecture to plug these accelerators into <br/> – Memory-Driven Computing.
              </div>
            </div>
          </div>
        
      </div>
    );
  }

}

export default UnconventionalPopup;