import React, { Component } from 'react';
import PropTypes from 'prop-types';
//import AccPopupImage from '../../assets/images/labs_novel.jpg';
import AccPopupVideo from '../../assets/images/light-traveling-salesman.mp4';
import ReactPlayer from 'react-player';

class LabsAccPopup extends Component {
  constructor(props){
    super(props);

    /*
    this.reloadGif = this.reloadGif.bind(this);
    this.state = { 
      gif: AccPopupImage,
      loaded: AccPopupImage
     };
     */
  }

  componentDidMount() {
    /*
    console.log('component mount.... reload gif'); 
    this.reloadGif();
    */
  }

  /*
  // https://stackoverflow.com/questions/48892134/force-gif-reload-in-react
  reloadGif = () => {

    //create random number;
    let num= Math.floor(Math.random() * 10000);
    this.setState({loaded: AccPopupImage+'?' + num.toString()})
    setTimeout(() => {
      this.setState({loaded: AccPopupImage+'?' + num.toString()})
    }, 100)
  }
  */

  render(){
      const {
        onCloseClick
      } = this.props;
    return(
      <div className="labsNovelPopup flexContainer">
        
        <div className='labsNovelPopupLeft'>
            <div className="labsNovelPopupTitle">
              LABS’ NOVEL ACCELERATORS
            </div>
            <div className='flexContainer popupContentAdjust'>
                <div className="labsNovelPopupDesc">
                    <p>
                        At Hewlett Packard Labs, research teams are working on solutions to previously impossible problems. The neuromorphic accelerator and other HPE research projects have given us a possible workaround for Moore’s Law and together suggest a way of supercharging computing’s flagging vitality.
                    </p>
                    <p>
                        Labs has three accelerators that harness our neuromorphic technology. This demo shows a typical AI application. We are also using our neuromorphic technology to build an optimization accelerator and a pattern-matching accelerator.
                    </p>
                    <p>
                        <span className='boldFamily'>OPTIMIZATION ACCELERATOR</span> <br/>
                        This is aimed at a class of what’s called “NP-hard” problems, which, if solved, could  
                    </p>
                </div>
                <div className="labsNovelPopupDesc2">
                    <p>
                        drive more efficient airline networks, logistics operations, social graphs, and even find the quickest way to solve a Rubik’s cube!  These problems are so challenging that some are considering quantum computing them. With our technology, you won’t have to wait decades.
                    </p>
                    <p>
                        <span className='boldFamily'>PATTERN-MATCHING ACCELERATOR</span> <br/>
                        Pattern matching is a powerful but computationally intensive task in many fields including network security and genomics. Labs is building an accelerator capable of complex searches almost instantaneously. Using very little energy, this accelerator enables us to move this workload all the way to the edge where the data is created or at key network points with dense data traffic.
                    </p>
                </div>
            </div>
        </div>
        <div className='labsNovelPopupRight'>
          <ReactPlayer
            className='labsNovelVideo'
            url={AccPopupVideo}
            muted={true}
            playing={true}
            loop={true}/>
        </div>
        
      </div>
    );
  }

}

export default LabsAccPopup;
