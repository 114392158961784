import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import infoIcon from '../../assets/images/info_icon.png';

const InfoTooltip = () => {

    return (
      <div className="infoTooltip" >
        <div data-tip data-for='device-connected' className='info-device-connected info-button'>
          <img src={infoIcon} />
        </div>
        <div data-tip data-for='device-breakdown' className='info-device-breakdown info-button'>
          <img src={infoIcon} />
        </div>
        <div data-tip data-for='dashboard' className='info-dashboard info-button'>
          <img src={infoIcon} />
        </div>
        <div data-tip data-for='command' className='info-command info-button'>
          <img src={infoIcon} />
        </div>
        <div data-tip data-for='dataflow' className='info-dataflow info-button'>
          <img src={infoIcon} />
        </div>
        <div data-tip data-for='neural' className='info-neural info-button'>
          <img src={infoIcon} />
        </div>
        <ReactTooltip className='infoPanel' id='device-connected' place="top" type="light" effect="solid">
            <strong>Device connected</strong>
            <br/>
            This graph shows the number of devices connected over the course of the game.
        </ReactTooltip>
        <ReactTooltip className='infoPanel' id='device-breakdown' place="top" type="light" effect="solid">
            <strong>Device breakdown</strong>
            <br/>
            This graph breaks down the connected devices by operating systems. The calculation can actually identify many more operating systems that are not represented here. The “Other” category flags suspect devices for further analysis.
        </ReactTooltip>
        <ReactTooltip className='infoPanel' id='dashboard' place="top" type="light" effect="solid">
            <strong>Dashboard for resource utilization</strong>
            <br/>
            In this demo, we are using 5 parallel processing engines called “tiles”. Each tile consists of 9 cores. This chart shows how busy each of the tiles inside of our accelerator are as the number of devices trying to connect fluctuates.
        </ReactTooltip>
        <ReactTooltip className='infoPanel' id='command' place="top" type="light" effect="solid">
            <strong>Command line</strong>
            <br/>
            The command line shows the code being executed by the neuromorphic accelerator.
        </ReactTooltip>
        <ReactTooltip className='infoPanel' id='dataflow' place="top" type="light" effect="solid">
            <strong>Dataflow</strong>
            <br/>
            The data flow chart shows data propagating through the cores of each tile. Each color corresponds to the data from one device.
        </ReactTooltip>
        <ReactTooltip className='infoPanel' id='neural' place="top" type="light" effect="solid">
            <strong>Neural Network</strong>
            <br/>
            This diagram shows the structure of a recurrent neural network algorithm that is being executed.
        </ReactTooltip>
      </div>
    );
}

export default InfoTooltip;
