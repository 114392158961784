import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {INPUT_ARR, RESULT_ARR} from '../../constants';

class CommandTextLoop extends Component {
  constructor(props){
    super(props);

    this.tick = this.tick.bind(this);

    this.state = { i: 0 };
  }

  componentDidMount() {
    //this.interval = setInterval(() => this.tick(), 100);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentWillReceiveProps(nextProps) {

    // set interval when user changes speed state, only do it when playing
    if (nextProps.isNormal !== this.props.isNormal && this.props.isPlaying) {
      clearInterval(this.interval);

      if(nextProps.isNormal) {
        this.interval = setInterval(() => this.tick(), 100);
      } else {
        this.interval = setInterval(() => this.tick(), 1000);
      }
    }

    // set interval when user changes playing state
    if(nextProps.isPlaying !== this.props.isPlaying) {
      if(!nextProps.isPlaying) {
        clearInterval(this.interval);
      }
      if(nextProps.isPlaying) {
        clearInterval(this.interval);
        if(this.props.isNormal) {
          this.interval = setInterval(() => this.tick(), 100);
        } else {
          this.interval = setInterval(() => this.tick(), 1000);
        }
      }
    }
  }

  tick() {

    let i = this.state.i;

    if(i === INPUT_ARR.length-1) {
      i = 0;
    } else {
      i = i + 1;
    }
    this.setState({
      i: i
    });
  }

  render(){
    return(
      <div className={this.props.sizeClass} style={{color: 'white'}}>
        <div>
          <span style={{color: 'purple'}}> INPUT: </span> {INPUT_ARR[this.state.i]}
        </div>
        <div>
          <span style={{color: 'yellow'}}> RESULT: </span> {RESULT_ARR[this.state.i]}
        </div>
      </div>
    );
  }

}

export default CommandTextLoop;
