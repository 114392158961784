import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import AccPopupImage from '../../assets/images/neromorphic.png';
import MemristorVid from '../../assets/videos/Memristor.mp4';

class LabsAccPopup extends Component {
  constructor(props){
    super(props);
    this.state = { i: 0 };
  }

  render(){
      const {
        onCloseClick
      } = this.props;
    return(
      <div className="labsPopup ">
        <div className="labsPopupTitle">
                LABS’ NEUROMORPHIC ACCELERATOR
        </div>
        <div className="flexContainer popupContentAdjust">
            <div className='labsPopupLeft'>
                <div className='flexContainer'>
                    <div className="labsPopupDesc">
                        <p>
                          If you look beneath the surface of computing tasks that we do everyday (for example, deep learning, video processing, graph analytics, streaming analytics), you’ll find the same mathematics: matrix multiplication. If you can find a better way to do matrix multiplication, then you’ve found a way to improve all of the things we just mentioned.
                        </p>
                        <p>
                          We believe this solution will be <span className='textHightlight'>10-100x faster</span> and requires <span className='textHightlight'>10-1000x less energy</span> than typical GPUs on a variety of neural network applications, especially for larger networks.
                        </p>
                    </div>
                    <div className="labsPopupDesc2">
                        <p>
                          Labs is developing the memristor technology and the software stack in parallel. To prove out the software stack, we have produced an FPGA, which you can think of as a hardware emulator. This demo shows the capability of our chip.
                        </p>
                        <p>
                          <span className='boldFamily'>WHY MEMRISTORS?</span> <br/>
                          Ordinary computers stores memory as 1s and 0s, but mathematics and the human brain doesn’t work like that. Using memristor, we can build an analog memory that can hold all the values between 1 and 0, that will remember a stored value even when the power goes away.
                        </p>
                    </div>
                </div>
                <div className='labsQuoteBox'>
                  <div>
                    Labs has developed a novel technology, the neuromorphic accelerator, that employs analog circuit elements to do matrix calculations in one step instead of executing thousands of lines of code.
                  </div>
                </div>

            </div>
            <div className='labsPopupRight'>
              
              <div className='labsPopupImgContainer'>
                <ReactPlayer
                  className='labsVideo'
                  url={MemristorVid}
                  muted={true}
                  playing={true}
                  loop={true}/>
                <div className='labsImageTitle boldFamily'>
                  Matrix Multiplication
                </div>
                <div className='labsImageText2'>
                  This is a schematic of what is happening inside of our neuromorphic accelerator chip.
                </div>
              </div>
                
            </div>
        </div>
        
      </div>
    );
  }

}

export default LabsAccPopup;
