import React, { Component } from 'react';
import PropTypes from 'prop-types';

const LearnMoreButtons = ({learnMoreClick}) => {

    return (
      <div className='learnMoreButtons'>
        <div className='learnMoreButton' onClick={() => learnMoreClick(0)}>WHAT IS AN ACCELERATOR?</div>
        <div className='learnMoreButton' onClick={() => learnMoreClick(1)}>WHY ACCELERATORS MATTER</div>
        <div className='learnMoreButton' onClick={() => learnMoreClick(2)}>UNCONVENTIONAL ACCELERATORS</div>
        <div className='learnMoreButton' onClick={() => learnMoreClick(3)}>NEUROMORPHIC ACCELERATORS</div>
        <div className='learnMoreButton' onClick={() => learnMoreClick(4)}>NOVEL ACCELERATORS</div>
      </div>
    );
}

export default LearnMoreButtons;
