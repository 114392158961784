const intialState = {
    showControl: false
}

export default (state = {}, action) => {
    switch (action.type) {
     case 'UPDATE_CONTROL':
      return {
        showControl: action.showControl
      }
     default:
      return state
    }
   }
   