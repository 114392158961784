import React, { Component } from 'react';
import PropTypes from 'prop-types';

const ControlInfoPiece = ({image, title, info}) => {
    return (
      <div className='controlInfoPiece flexContainer'>
        <div className='controlInfoImg'>
            <img src={image}/>
        </div>
        <div className='controlInfoText'>
            <div style={{fontWeight: 'bold'}}>
                {title}
            </div>
            <div>
                {info}
            </div>
        </div>
      </div>
    );
}

export default ControlInfoPiece;
