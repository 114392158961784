import React, { Component } from 'react';
import logo from '../../logo.svg';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { simpleAction } from '../../actions/simpleAction'
import { IDLE_TIME } from '../../constants';
import ReactPlayer from 'react-player';
//import accVideo from '../../assets/videos/02-Accelerator-1min-full-1080.mp4';
import accVideo from '../../assets/videos/02-Accelerator-1min-full-1080.mp4';
import { PauseFill, PlayFill } from 'grommet-icons';
import styled from 'styled-components';
import playImage from '../../assets/images/play.png';
import pauseImage from '../../assets/images/pause.png';
import dataText from '../../assets/images/data-texts-02.png';
import CommandTextLoop from '../../components/CommandTextLoop';
import ImgPopup from '../../components/ImgPopup';
import networkDiagram from '../../assets/images/network-diagram.svg';
import networkButtonImg from '../../assets/images/network-button.png';
import WhatisAccPopup from '../../components/WhatisAccPopup';
import LearnMoreParent from '../../components/LearnMorePanelParent';
import LearnMoreButtons from '../../components/LearnMoreButtons';
import InfoTooltip from '../../components/InfoTooltip';
import HPELOGO from '../../assets/images/labs-logo.png';
import homeImage from '../../assets/images/home.png';


const mapStateToProps = state => ({
 controlReducer: state.controlReducer
})

const mapDispatchToProps = dispatch => ({
 simpleAction: () => dispatch(simpleAction())
})

class App extends Component {

  constructor(props) {
    super(props);

    this.idleTimer = null
    this.onIdle = this._onIdle.bind(this)
    this.updatePlaybackRate = this.updatePlaybackRate.bind(this);
    this.toggleOrientation = this.toggleOrientation.bind(this);
    this.togglePlayback = this.togglePlayback.bind(this);
    this.unmute = this.unmute.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.setLearnPanelPopup = this.setLearnPanelPopup.bind(this);
    this.setLearnPanelId = this.setLearnPanelId.bind(this);
    this.onHomeClick = this.onHomeClick.bind(this);
    
    this.state = {
      isLearnMoreOpen: true,
      learnMoreId: 6,
      playbackRate: 1,
      isNormal: true,
      played: 0,
      isPlaying: false,
      isPlayingOld: false,
      isHorizontal: false,
      firstTimeLoad: true,
      muted: true,
      isPopupLeftOpen: false,
      isPopupRightOpen: false,
    };
  }

  componentDidMount() {
    /*
    console.log(this.props.controlReducer);
    //check if user came from intro page by looking at store
    if(this.props.controlReducer.showControl) {
      this.setState({
        isLearnMoreOpen: true,
        isPlaying: false
      })
    }
    */
  }

  onHomeClick() {
    this.props.history.push('/')
  }

  setLearnPanelPopup(b) {
    if(this.state.firstTimeLoad && this.state.learnMoreId === 6 && !b) {

      // closing control info panel the first time, play video
      this.setState({
        isLearnMoreOpen: b,
        firstTimeLoad: false,
        isPlaying: true,
        isPlayingOld: true,
      });
    } else {
      this.setState({
        isLearnMoreOpen: b,
        isPlaying: this.state.isPlayingOld,
      });
    }
    
  }

  setLearnPanelId(i) {
    this.setState({
      learnMoreId: i,
      isPlaying: false,
      isLearnMoreOpen: true
    });
  }

  updatePlaybackRate(b) {
    let num = 1;

    // if false then slow is active
    if(!b) {
      num = 0.25;
    }
    let isNormal = num === 1 ? true : false;
    this.setState({
      playbackRate: num,
      isNormal: isNormal
    });
  }

  togglePlayback() {
    let isPlaying = !this.state.isPlaying;
    this.setState({
      isPlaying: isPlaying,
      isPlayingOld: isPlaying
    });
  }

  toggleOrientation() {
    let orientation = !this.state.isHorizontal;
    this.setState({
      isHorizontal: orientation
    });
  }

  togglePopup(side) {
    console.log(side)
    if(side === 'left') {
      let isPopupLeftOpen = !this.state.isPopupLeftOpen;
      this.setState({
        isPopupLeftOpen: isPopupLeftOpen
      });
    } else {
      let isPopupRightOpen = !this.state.isPopupRightOpen;
      this.setState({
        isPopupRightOpen: isPopupRightOpen
      });
    }
  }

  unmute() {
    this.setState({
      muted: false
    });
  }

  onSeekMouseDown = e => {
    this.setState({ seeking: true })
  }
  onSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) })
  }
  onSeekMouseUp = e => {
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e.target.value))
  }
  onProgress = state => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state)
    }
  }

  ref = player => {
    this.player = player
  }
 
  _onIdle(e) {
    console.log('user is idle', e)
    this.props.history.push('/')
  }

  render() {

    const {
      isHorizontal,
      isNormal,
      isPlaying,
      played,
      playbackRate
    } = this.state;

    return (
      <div className='app'  onClick={this.state.muted ? this.unmute : undefined}>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 10} />
        <div className='playerContainer'>
          <ReactPlayer
            ref={this.ref}
            className={isHorizontal ? 'player' : 'player'}
            onSeek={e => console.log('onSeek', e)}
            url={accVideo}
            onProgress={this.onProgress}
            muted={this.state.muted || playbackRate !== 1}
            playing={isPlaying}
            loop={true}
            progressInterval={100}
            playbackRate={playbackRate}/>
        </div>
        <div className='homeButton' onClick={this.onHomeClick}>
          <img src={homeImage} />
        </div>
        {this.state.isPopupLeftOpen &&
          <div className={isHorizontal? 'popup popup-left' : 'popup popup-flip'} >
            <ImgPopup imgSrc={networkDiagram} onClick={() => this.togglePopup('left')}/>
          </div>
        }
        <img className={isHorizontal? 'networkButtonImg-left' : 'networkButtonImg-flip'} src={networkButtonImg} onClick={() => this.setLearnPanelId(5)}/>
        <div className={isHorizontal ? 'graphBorder' : 'graphBorder-flip'}/>
        <div className={isHorizontal ? 'dataText' : 'dataText-flip'}>
          <img src={dataText} /> // FIGURE OUT
        </div>
        <div className={isHorizontal ? 'commandTextLoop-left' : 'commandTextLoop-flip'}>
          <CommandTextLoop isPlaying={isPlaying} sizeClass={isHorizontal ? 'commandTextLoop' : 'commandTextLoop-large'} isNormal={isNormal}/>
        </div>
        <div className='controlContainer'>
          <div className = {isHorizontal? 'control control-right ' : ' control control-flip'}>
            <div
              className={isPlaying? 'pause-button' : 'play-button'}
              onClick={this.togglePlayback}
            />
            <div className='speed-button-container'>
              <div className={isNormal ? 'playbackButton normal-button' : 'playbackButton slow-button' } onClick={() => this.updatePlaybackRate(!isNormal)} />
            </div>
          </div>
            <input
              id='myRange'
              className = {isHorizontal? 'slider input input-right ' : 'slider input input-flip'}
              type='range' min={0} max={1} step='any'
              value={played}
              onTouchStart={this.onSeekMouseDown}
              onMouseDown={this.onSeekMouseDown}
              onChange={this.onSeekChange}
              onTouchEnd={this.onSeekMouseUp}
              onMouseUp={this.onSeekMouseUp}
            />
            <div className='learnMoreSection'>
              <LearnMoreButtons panelId={this.state.learnMoreId} learnMoreClick={this.setLearnPanelId} />
            </div>
          { this.state.isLearnMoreOpen &&
            <span>
              <div className='descriptionPanelBackground' onClick={() => this.setLearnPanelPopup(false)}/>
              <div className='center'>
                <LearnMoreParent learnMoreClick={this.setLearnPanelId} panelId={this.state.learnMoreId} onCloseClick={() => this.setLearnPanelPopup(false)} />
              </div>
            </span>
            
          }
        </div>
        <InfoTooltip />
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
