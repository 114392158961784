// TODO move this to container folder now that it connects to redux

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateControl } from '../../actions/controlAction'
import ReactPlayer from 'react-player';
import introVideo from '../../assets/videos/Accelerator-1min-full-1080-background-01.mp4';
import hpeLogo from '../../assets/images/labs-logo.png';

const mapStateToProps = state => ({
  ...state
 })
 
 const mapDispatchToProps = dispatch => ({
  updateControlAction: (b) => dispatch(updateControl(b))
 })

class IntroPage extends Component {
  constructor(props){
    super(props);

    this.onStartClick = this.onStartClick.bind(this);
  }

  onStartClick() {
    this.props.updateControlAction(true);
    this.props.history.push('/main')
  }

  render(){

    return(
      <div className='introPage '>
        <div className='introPlayerContainer'>
          <ReactPlayer
            ref={this.ref}
            className={'introPlayer'}
            url={introVideo}
            muted={true}
            playing={true}
            loop={true}/>
        </div>
        <div className='introPagePanel'>
            <div  className='introPageContent'>
                <img src={hpeLogo}/>
                <div className='introBorder'></div>
                <div className='introPageTitle'>
                    SEE THE NEUROMORPHIC <span style={{color: '#01a982'}}>ACCELERATOR</span> IN ACTION!
                </div>

                <div className='introPageDesc'>
                  This demo shows the power and energy efficiency of Hewlett Packard Labs’ neuromorphic accelerator using a network security use case set inside a sports stadium during a game.       
                  <br/>
                  <br/>
                  Our accelerator will seamlessly filter and detect potential security network risks in real-time. In particular, our accelerator will perform one step of a network security process at the access point as the crowd fills the soccer stadium and people connect their devices to the Wi-Fi network.         
                </div>
            </div>
        </div>
        <div className='startButton' onClick={this.onStartClick}>LET'S GET STARTED</div>
      </div>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(IntroPage);
