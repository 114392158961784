export const OUTPUT_DATA = [
  {INPUT: "{'dhcp': {'option55': ['1,121,3,6,15,119,252'], 'options': ['53,55,57,61,50,54,12']}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.1; Trident/7.0; rv:11.0; GTB7.5) like Gecko'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/4.0 (compatible; MSIE 8.0; Windows NT 6.1; WOW64; Trident/4.0; SLCC2; .NET CLR 2.0.50727; .NET CLR 3.5.30729; .NET CLR 3.0.30729; Media Center PC 6.0; dtsPdfWh; InfoPath.2; .NET4.0C; .NET4.0E)'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,61,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; Android 6.0.1; SAMSUNG-SM-G920A Build/MMB29K) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/4.0 Chrome/44.0.2403.133 Mobile Safari/537.36'}}",   RESULT: "Samsung Android"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,61,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; Android 5.0; SM-G900V Build/LRX21T) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/41.0.2272.96 Mobile Safari/537.36'}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/33.0.1750.154 Safari/537.36'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/4.0 (compatible; MSIE 8.0; Windows NT 6.1; WOW64; Trident/4.0; SLCC2; .NET CLR 2.0.50727; .NET CLR 3.5.30729; .NET CLR 3.0.30729; Media Center PC 6.0; .NET4.0E; .NET4.0C; InfoPath.2)'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59,119'], 'option60': ['dhcpcd-5.2.10:Linux-3.0.8-svn1454:armv7l:warp2'], 'options': ['53,50,54,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; U; Android 4.0.4; en-us; N861 Build/IMM76D) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 Mobile Safari/534.30'}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 7_1 like Mac OS X) AppleWebKit/537.51.2 (KHTML, like Gecko) Version/7.0 Mobile/11D167 Safari/9537.53'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,50,54,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; Android 4.4.2; XT1034 Build/KXB20.9-1.10-1.24-1.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/34.0.1847.114 Mobile Safari/537.36'}}",   RESULT: "Motorola Android"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,252,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Windows Store/1.0'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,26,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,61,50,54,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; Android 4.4.2; SAMSUNG-SGH-I257 Build/KOT49H) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/40.0.2214.109 Mobile Safari/537.36'}}",   RESULT: "Samsung Android"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/34.0.1847.131 Safari/537.36'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,121,3,6,15,119,252'], 'options': ['53,55,57,61,50,54,12']}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,121,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPad; CPU OS 9_0_2 like Mac OS X) AppleWebKit/601.1.46 (KHTML, like Gecko) Version/9.0 Mobile/13A452 Safari/601.1'}}",   RESULT: "Apple iPad"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 7_1_2 like Mac OS X) AppleWebKit/537.51.2 (KHTML, like Gecko) Version/7.0 Mobile/11D257 Safari/9537.53'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,252,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.3; WOW64; rv:35.0) Gecko/20100101 Firefox/35.0'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; U; CPU iPhone OS 5_0 like Mac OS X) AppleWebKit/534.46 (KHTML, like Gecko) Version/5.1 Mobile/9A334 Safari/7534.48.3'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,252,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.3; WOW64; rv:30.0) Gecko/20100101 Firefox/30.0'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,252,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.3; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/33.0.1750.154 Safari/537.36'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,54,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 8_1_2 like Mac OS X) AppleWebKit/600.1.4 (KHTML, like Gecko) Version/8.0 Mobile/12B440 Safari/600.1.4'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,54,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 7_1_1 like Mac OS X) AppleWebKit/537.51.2 (KHTML, like Gecko) Version/7.0 Mobile/11D201 Safari/9537.53'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,54,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPad; CPU OS 6_1_2 like Mac OS X) AppleWebKit/536.26 (KHTML, like Gecko) Version/6.0 Mobile/10B146 Safari/8536.25'}}",   RESULT: "Apple iPad"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,12,15,28,40,41,42'], 'option60': ['udhcp 1.7.2'], 'options': ['53,61,60,50,54,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; like iPhone; U; en-US) AppleWebKit/528.5+ (KHTML, like Gecko, Safari/528.5+) Version/4.0 Kindle/3.0 (screen 600x800; rotate)'}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,252,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.2; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.95 Safari/537.36'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.2.10'], 'options': ['53,50,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; U; Android 4.0.3; en-gb; KFOT Build/IML74K) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 Mobile Safari/534.30'}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 7_1_2 like Mac OS X) AppleWebKit/537.51.2 (KHTML, like Gecko) Version/7.0 Mobile/11D257 Safari/9537.53'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,61,50,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; Android 5.1.1; SM-T280 Build/LMY47V) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/53.0.2785.124 Safari/537.36'}}",   RESULT: "Samsung Android"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'iPhone5,2/7.1.1 (11D201)'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,26,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,61,50,54,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; Android 4.4.4; Nexus 7 Build/KTU84P) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/38.0.2125.114 Safari/537.36'}}",   RESULT: "AsusTek Android"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.2.10'], 'options': ['53,50,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; U; Android 4.0.3; en-us; KFTT Build/IML74K) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 Safari/534.30'}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,61,50,54,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; Android 5.0.1; SAMSUNG SCH-I545 4G Build/LRX22C) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/2.1 Chrome/34.0.1847.76 Mobile Safari/537.36'}}",   RESULT: "Samsung Android"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,61,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; Android 4.2.2; GT-I9195 Build/JDQ39) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/38.0.2125.114 Mobile Safari/537.36'}}",   RESULT: "Samsung Android"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,252,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.3; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/41.0.2272.118 Safari/537.36'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,252,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.2; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/35.0.1916.153 Safari/537.36'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}, 'host': {'user_agent': 'Microsoft-CryptoAPI/6.1'}}",   RESULT: "Windows Vista/7/2008"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 6_0 like Mac OS X) AppleWebKit/536.26 (KHTML, like Gecko) Version/6.0 Mobile/10A403 Safari/8536.25'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}, 'host': {'user_agent': 'Microsoft NCSI'}}",   RESULT: "Windows Vista/7/2008"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,61,50,54,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; U; Android 4.2.2; en-us; SPH-L520 Build/JDQ39) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 Mobile Safari/534.30'}}",   RESULT: "Samsung Android"},
  {INPUT: "{'dhcp': {'option55': ['1,121,3,6,15,119,252'], 'options': ['53,55,57,61,50,54,12']}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,12,15,28,40,41,42'], 'option60': ['udhcp 1.7.2'], 'options': ['53,61,60,50,54,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; like iPhone; U; en-US) AppleWebKit/528.5+ (KHTML, like Gecko, Safari/528.5+) Version/4.0 Kindle/3.0 (screen 600x800; rotate)'}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,54,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 7_1 like Mac OS X) AppleWebKit/537.51.2 (KHTML, like Gecko) Version/7.0 Mobile/11D167 Safari/9537.53'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPod; CPU iPhone OS 6_1_6 like Mac OS X) AppleWebKit/536.26 (KHTML, like Gecko) Version/6.0 Mobile/10B500 Safari/8536.25'}}",   RESULT: "Apple iPod"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 8_3 like Mac OS X) AppleWebKit/600.1.4 (KHTML, like Gecko) Version/8.0 Mobile/12F70 Safari/600.1.4'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/41.0.2272.118 Safari/537.36'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 7_1_1 like Mac OS X) AppleWebKit/537.51.2 (KHTML, like Gecko) Version/7.0 Mobile/11D201 Safari/9537.53'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/35.0.1916.114 Safari/537.36'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,54,12']}, 'host': {'user_agent': 'MobileSafari/9537.53 CFNetwork/672.0.2 Darwin/14.0.0'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,252,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.3; WOW64; rv:28.0) Gecko/20100101 Firefox/28.0'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}}",   RESULT: "Windows Vista/7/2008"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,61,50,54,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; Android 6.0.1; SM-G928V Build/MMB29K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/51.0.2704.81 Mobile Safari/537.36'}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,54']}, 'host': {'user_agent': 'Mozilla/5.0 (iPod; U; CPU iPhone OS 4_2_1 like Mac OS X; en-us) AppleWebKit/533.17.9 (KHTML, like Gecko) Version/5.0.2 Mobile/8C148 Safari/6533.18.5'}}",   RESULT: "Apple iPod"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,252,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Microsoft-CryptoAPI/6.3'}}",   RESULT: "Windows 8"},
  {INPUT: "{'dhcp': {'option55': ['1,121,3,6,15,119,252'], 'options': ['53,55,57,61,50,54,12']}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/4.0 (compatible; MSIE 8.0; Win32)'}}",   RESULT: "Windows Vista/7/2008"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,252,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.3; WOW64; Trident/7.0; Touch; ASU2JS; rv:11.0) like Gecko'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,121,3,6,15,119,252'], 'options': ['53,55,57,61,51,12']}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,61,50,54,57,60,12,55']}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,54,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPad; CPU OS 8_1_1 like Mac OS X) AppleWebKit/600.1.4 (KHTML, like Gecko) Version/8.0 Mobile/12B435 Safari/600.1.4'}}",   RESULT: "Apple iPad"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 7_0_4 like Mac OS X) AppleWebKit/537.51.1 (KHTML, like Gecko) Version/7.0 Mobile/11B554a Safari/9537.53'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.1; WOW64; rv:30.0) Gecko/20100101 Firefox/30.0'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.1; WOW64; Trident/7.0; rv:11.0) like Gecko'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,26,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,61,50,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; Android 4.4.2; Nexus 7 Build/KVT49L) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/35.0.1916.141 Safari/537.36'}}",   RESULT: "AsusTek Android"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,252,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; ARM; Trident/6.0; Touch)'}}",   RESULT: "Windows Device"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.1; WOW64; rv:27.0) Gecko/20100101 Firefox/27.0'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,54,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 7_1_1 like Mac OS X) AppleWebKit/537.51.2 (KHTML, like Gecko) Version/7.0 Mobile/11D201 Safari/9537.53'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 7_1_2 like Mac OS X) AppleWebKit/537.51.2 (KHTML, like Gecko) Version/7.0 Mobile/11D257 Safari/9537.53'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPad; CPU OS 7_0_2 like Mac OS X) AppleWebKit/537.51.1 (KHTML, like Gecko) Version/7.0 Mobile/11A501 Safari/9537.53'}}",   RESULT: "Apple iPad"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['android-dhcp-6.0'], 'options': ['53,50,54,57,60,12,55']}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPad; CPU OS 8_0_2 like Mac OS X) AppleWebKit/600.1.4 (KHTML, like Gecko) Version/8.0 Mobile/12A405 Safari/600.1.4'}}",   RESULT: "Apple iPad"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,252,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; WOW64; Trident/6.0)'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.1; WOW64; Trident/7.0; rv:11.0) like Gecko'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.2.10'], 'options': ['53,50,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; U; Android 4.1.1; ko-kr; Galaxy Nexus Build/JRO03C) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 Mobile Safari/534.30'}}",   RESULT: "Samsung Android"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/36.0.1985.125 Safari/537.36'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 7_1_2 like Mac OS X) AppleWebKit/537.51.2 (KHTML, like Gecko) Mobile/11D257'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,61,50,54,57,60,12,55']}}",   RESULT: "Samsung Android"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43,0,128,112'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Skype WISPr'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Microsoft NCSI'}}",   RESULT: "Windows Vista/7/2008"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,26,28,51,58,59,43'], 'option60': ['android-dhcp-7.0'], 'options': ['53,61,50,54,57,60,12,55']}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,252,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/4.0 (compatible; GoogleToolbar 7.5.5111.1712; Windows 6.2; MSIE 9.10.9200.16863)'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.2.10'], 'options': ['53,50,57,60,12,55']}, 'host': {'user_agent': 'kindle/2.0'}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 7_1_2 like Mac OS X) AppleWebKit/537.51.2 (KHTML, like Gecko) Version/7.0 Mobile/11D257 Safari/9537.53'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55,43']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 5.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/38.0.2125.111 Safari/537.36'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (compatible; MSIE 9.0; Windows NT 6.1; WOW64; Trident/5.0)'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPad; CPU OS 8_1_2 like Mac OS X) AppleWebKit/600.1.4 (KHTML, like Gecko) CriOS/40.0.2214.69 Mobile/12B440 Safari/600.1.4'}}",   RESULT: "Apple iPad"},
  {INPUT: "{'dhcp': {'option55': ['1,121,3,6,15,119,252'], 'options': ['53,55,57,61,50,54,12']}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,252,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (compatible; MSIE 10.0; Windows Phone 8.0; Trident/6.0; IEMobile/10.0; ARM; Touch; NOKIA; Lumia 920)'}}",   RESULT: "Windows Device"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPad; CPU OS 5_1_1 like Mac OS X) AppleWebKit/534.46 (KHTML, like Gecko) Version/5.1 Mobile/9B206 Safari/7534.48.3'}}",   RESULT: "Apple iPad"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,50,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; Android 4.2.2; QMV7A Build/JDQ39) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/36.0.1985.135 Safari/537.36'}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,61,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; U; Android 4.4.2; en-us; HTC6500LVW 4G Build/KOT49H) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 Mobile Safari/534.30'}}",   RESULT: "HTC Android"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.1; WOW64; Trident/7.0; rv:11.0) like Gecko'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,50,54,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; Android 5.1; Z820 Build/LMY47D) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/51.0.2704.81 Mobile Safari/537.36'}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/40.0.2214.93 Safari/537.36'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/534.57.2 (KHTML, like Gecko) Version/5.1.7 Safari/534.57.2'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 8_1_2 like Mac OS X) AppleWebKit/600.1.4 (KHTML, like Gecko) Version/8.0 Mobile/12B440 Safari/600.1.4'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,26,28,51,58,59'], 'option60': ['android-dhcp-6.0.1'], 'options': ['53,61,50,54,57,60,12,55']}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,121,33,3,6,15,28,51,58,59,119'], 'option60': ['dhcpcd 4.0.15'], 'options': ['53,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; U; Android 2.3.6; en-us; LG-L38C Build/GRK39F) AppleWebKit/533.1 (KHTML, like Gecko) Version/4.0 Mobile Safari/533.1 MMS/LG-Android-MMS-V1.0/1.2'}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,121,33,3,6,15,28,51,58,59,119'], 'option60': ['dhcpcd 4.0.15'], 'options': ['53,50,54,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; U; Android 2.3.3; en-us; C771 Build/C771M150) AppleWebKit/533.1 (KHTML, like Gecko) Version/4.0 Mobile Safari/533.1'}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,54']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 6_1_4 like Mac OS X) AppleWebKit/536.26 (KHTML, like Gecko) Version/6.0 Mobile/10B350 Safari/8536.25'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,121,3,6,15,119,252'], 'options': ['53,55,57,61,51,12']}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,26,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,61,50,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; U; Android 4.4.2; en-us; SAMSUNG-SGH-I747 Build/KOT49H) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 Mobile Safari/534.30'}}",   RESULT: "Samsung Android"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.1; WOW64; Trident/6.0)'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 7_0_3 like Mac OS X) AppleWebKit/537.51.1 (KHTML, like Gecko) Version/7.0 Mobile/11B511 Safari/9537.53'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,61,50,54,57,60,12,55']}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}, 'host': {'user_agent': 'Microsoft NCSI'}}",   RESULT: "Windows Vista/7/2008"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,26,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,61,50,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; Android 5.1.1; Nexus 5 Build/LMY48B) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/43.0.2357.93 Mobile Safari/537.36'}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.1; WOW64; Trident/7.0; rv:11.0) like Gecko'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,54,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 8_1_3 like Mac OS X) AppleWebKit/600.1.4 (KHTML, like Gecko) Version/8.0 Mobile/12B466 Safari/600.1.4'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.2.10'], 'options': ['53,61,50,54,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; U; Android 4.1.2; en-us; GT-N8013 Build/JZO54K) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 Safari/534.30'}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,50,54,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; Android 4.4.2;  en-us; MXW1  Build/MocorDroid)  AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/30.0.0.0 Mobile Safari/537.36'}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,61,50,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; U; Android 4.3; zh-cn; SM-N9008V Build/JSS15J) AppleWebKit/537.36 (KHTML, like Gecko)Version/4.0 MQQBrowser/5.2 Mobile Safari/537.36'}}",   RESULT: "Samsung Android"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (compatible; MSIE 9.0; Windows NT 6.1; WOW64; Trident/5.0)'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.2.10'], 'options': ['53,50,54,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; U; Android 4.1.1; en-us; SGH-T869 Build/JRO03R; CyanogenMod-10) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 Safari/534.30 (Mobile; afma-sdk-a-v6.2.1)'}}",   RESULT: "Samsung Android"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.1; WOW64; Trident/7.0; rv:11.0) like Gecko'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,121,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 8_1_2 like Mac OS X) AppleWebKit/600.1.4 (KHTML, like Gecko) Version/8.0 Mobile/12B440 Safari/600.1.4'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,12,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/34.0.1847.116 Safari/537.36'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPod touch; CPU iPhone OS 8_1_2 like Mac OS X) AppleWebKit/600.1.4 (KHTML, like Gecko) Version/8.0 Mobile/12B440 Safari/600.1.4'}}",   RESULT: "Apple iPod"},
  {INPUT: "{'dhcp': {'option55': ['1,3,6,15,119,252'], 'options': ['53,55,57,61,50,51,12']}, 'host': {'user_agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 7_1_2 like Mac OS X) AppleWebKit/537.51.2 (KHTML, like Gecko) Mobile/11D257'}}",   RESULT: "Apple iPhone"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.2.10'], 'options': ['53,61,50,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; U; Android 4.1.2; en-us; SGH-T889 Build/JZO54K) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 Mobile Safari/534.30'}}",   RESULT: "Samsung Android"},
  {INPUT: "{'dhcp': {'option55': ['1,121,33,3,6,15,28,51,58,59,119'], 'option60': ['dhcpcd 4.0.15'], 'options': ['53,57,60,12,55']}, 'host': {'user_agent': 'kindle/2.0'}}",   RESULT: "Android"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/34.0.1847.116 Safari/537.36'}}",   RESULT: "Windows"},
  {INPUT: "{'dhcp': {'option55': ['1,33,3,6,15,28,51,58,59'], 'option60': ['dhcpcd-5.5.6'], 'options': ['53,61,50,54,57,60,12,55']}, 'host': {'user_agent': 'Mozilla/5.0 (Linux; U; Android 4.3; en-us; SPH-L710 Build/JSS15J) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 Mobile Safari/534.30'}}",   RESULT: "Samsung Android"},
  {INPUT: "{'dhcp': {'option55': ['1,15,3,6,44,46,47,31,33,121,249,43'], 'option60': ['MSFT 5.0'], 'options': ['53,61,50,54,12,81,60,55']}}",   RESULT: "Windows Vista/7/2008"}
]

export const INPUT_ARR = OUTPUT_DATA.map(a => a.INPUT);

export const RESULT_ARR = OUTPUT_DATA.map(a => a.RESULT);
