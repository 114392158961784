import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Close } from 'grommet-icons';
import AccPopupImage from '../../assets/images/what_image.jpg';

class WhatisAccPopup extends Component {
  constructor(props){
    super(props);
    this.state = { i: 0 };
  }

  render(){
      const {
          closeClicked
      } = this.props;
    return(
      <div className='accPopup'>
        <div className='accPopupLeft accZfix'>
          <div className='accPopupTitle'>
              WHAT IS AN ACCELERATOR?
          </div>
          <div className='accPopupDesc '>
              <p>
                An accelerator is a special purpose computing device intended to perform a specific task or tasks more rapidly and efficiently than a general purpose microprocessor.
              </p>
              <p>
                A computing system equipped with accelerators is able to offload workloads to those accelerators to improve overall system performance.
              </p>
              <p>
                An example of an accelerator that many people are familiar with is a Graphics Processing Unit (GPU), which is very good at manipulating video and/or images or the chip that handles voice recognition inside a smartphone.
              </p>
          </div>
        </div>
        <img className='whatBackground' src={AccPopupImage}/>
        <div className='whatFade' />
      </div>
    );
  }

}

export default WhatisAccPopup;
