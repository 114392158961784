import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AccPopupImage from '../../assets/images/neural_network.png';

class RecurrentPopup extends Component {
  constructor(props){
    super(props);
    this.state = { i: 0 };
  }

  render(){
      const {
        onCloseClick
      } = this.props;
    return(
      <div className="recurrentPopup flexContainer">
        
        <div className='recurrentPopupLeft'>
            <div className="recurrentPopupTitle">
                RECURRENT NEURAL NETWORK
            </div>
            <div className='flexContainer popupContentAdjust'>
                <div className="recurrentPopupDesc">
                    <p>
                        Neural networks are loosely modeled after the human brain and are designed to recognize patterns. Through a series of layers of machine "neurons", an input such as a photograph is gradually turned into a simple answer, such as "kitten!".
                    </p>
                    <p>
                        This particular task is usually performed by a specific type of algorithm called a concurrent neural network, or CNN. CNNs are very good at this kind of static, one-time prediction. "Is there a cat in this photo? What kind?" But sometimes, we want to add a time element and predict what's coming next.
                    </p>
                    <p>
                        A great example is natural language processing.
                    </p>
                </div>
                <div className="recurrentPopupDesc2">
                    <p>
                        You probably use this all the time when you type text into your smartphone and see the autocomplete suggestions.
                    </p>
                    <p>
                        Recurrent neural networks, or RNNs, are good at this kind of prediction. They add recurrence, or feedback loops, into the calculation to allow them to predict what comes next.
                    </p>
                    <p>
                        Data scientists experiment with different algorithms, like CNNs and RNNs, to find out which one works best to complete a particular task. In our use case, it turns out that an RNN is an efficient way to determine if a device trying to join the network at our soccer stadium is what it says it is.
                    </p>
                </div>
            </div>
        </div>
        <div className='recurrentPopupRight'>
            <img src={AccPopupImage}/>
        </div>
        
      </div>
    );
  }

}

export default RecurrentPopup;
