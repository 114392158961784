import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Close } from 'grommet-icons';
import WhatisAccPopup from '../../components/WhatisAccPopup';
import WhyAccPopup from '../../components/WhyAccPopup';
import UnconventionalPopup from '../../components/UnconventionalPopup';
import LabsAccPopup from '../../components/LabsAccPopup';
import LabsNovelPopup from '../../components/LabsNovelPopup';
import FooterPopup from '../../components/FooterPopup';
import RecurrentPopup from '../RecurrentPopup';
import ControlsPopup from '../ControlsPopup';

class LearnMorePanelParent extends Component {
  constructor(props){
    super(props);
    this.state = { i: 0 };
  }

  render(){
      const {
        onCloseClick,
        panelId,
        learnMoreClick
      } = this.props;

      let panelComponent = (<WhyAccPopup/>);
      switch (panelId) {
        case 0:
          panelComponent = (<WhatisAccPopup/>);
          break;
        case 1: 
          panelComponent = (<WhyAccPopup/>);
          break;
        case 2: 
          panelComponent = (<UnconventionalPopup/>);
          break;
        case 3: 
          panelComponent = (<LabsAccPopup/>);
          break;
        case 4:
          panelComponent = (<LabsNovelPopup/>);
          break;
        case 5:
          panelComponent = (<RecurrentPopup/>);
          break;
        case 6:
          panelComponent = (<ControlsPopup/>);
          break;
        default: 
          panelComponent = (<UnconventionalPopup/>);
      }
    return(
      <div className='descriptionPanelParent'>
          {panelComponent}
          {panelId !== 6 &&
            <FooterPopup panelId={panelId} learnMoreClick={learnMoreClick} />
          }
          <Close className='learnMoreClose' color='white' size='large' onClick={onCloseClick}/>
      </div>
    );
  }

}

export default LearnMorePanelParent;