import React, { Component } from 'react';
import PropTypes from 'prop-types';

const ImgPopup = ({imgSrc, onClick}) => {

    return (
      <div className="imgPopup" onClick={onClick}>
        <img src={imgSrc} />
      </div>
    );
}

export default ImgPopup;
