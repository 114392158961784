import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import configureStore from './store';
import './index.css';
import App from './containers/AppPage/';
import IntroPage from './components/IntroPage/';

// main app scss
import './styles/index.scss';

ReactDOM.render((
  <Provider store={configureStore()}>
    <HashRouter>
      <div>
        <Route exact path="/" component={IntroPage} />
        <Route exact path="/main" component={App} />
      </div>
    </HashRouter>
  </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
